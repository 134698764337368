import React from 'react';
import { Link } from 'react-router-dom';

const ResearchArea = ({ title, description, icon }) => (
  <div className="relative p-8 bg-white rounded-lg shadow-lg transform transition duration-500 hover:scale-105">
    <div className="flex items-center justify-center w-12 h-12 rounded-md bg-neu-red text-white mb-4">
      {icon}
    </div>
    <h3 className="text-2xl font-bold text-gray-900 mb-4">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const Research = () => {
  const researchAreas = [
    {
      title: "Vascular fluid flows",
      description: "We study vascular fluid flows in the context of drug delivery to tumors, using advanced microfluidic techniques to model complex vascular networks.",
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7 7-7 7M21 8l-7 7 7 7" />
        </svg>
      ),
    },
    {
      title: "Tumor tissue properties",
      description: "Our research investigates tumor tissue properties and their role in cancer progression, focusing on the physical and biological characteristics that influence tumor growth and metastasis.",
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 2a10 10 0 110 20 10 10 0 010-20zm0 6a4 4 0 100 8 4 4 0 000-8z" />
        </svg>
      ),
    },
    {
      title: "Chemotherapy-induced alterations",
      description: "We examine chemotherapy-induced alterations to the tumor vasculature and their consequences on patient response to treatment, aiming to optimize therapeutic strategies and improve clinical outcomes.",
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12H9m2-6v12m-7.071 2.929L5.929 19m12.142 0l-1.414 1.414M4.222 4.222l1.414 1.414m13.728 0l-1.414 1.414M12 3a9 9 0 110 18 9 9 0 010-18z" />
        </svg>
      ),
    },
  ];

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-neu-red tracking-wide uppercase">Our Work</h2>
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Research Areas
          </p>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            Exploring the intersection of engineering and cancer biology to improve patient outcomes.
          </p>
        </div>

        <div className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {researchAreas.map((area, index) => (
            <ResearchArea key={index} title={area.title} description={area.description} icon={area.icon} />
          ))}
        </div>

        <div className="mt-16 bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Our Approach
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>
                At the TUMOR Lab, we specialize in the design of microfluidic, tissue-engineered models of vascularized tumors. Our interdisciplinary approach combines engineering principles with biological insights to tackle complex challenges in cancer research.
              </p>
            </div>
            <div className="mt-3 text-sm">
              <Link to="/publications" className="font-medium text-neu-red hover:text-neu-red-dark">
                Learn more about our methods <span aria-hidden="true">&rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Research;
