import React, { useState } from 'react';
import { motion } from 'framer-motion';

const ApplicationProcess = ({ title, steps }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-8">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full text-left"
      >
        <h3 className="text-xl font-semibold text-gray-800 mb-2 flex items-center">
          <span>{title}</span>
          <svg
            className={`ml-2 h-5 w-5 transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </h3>
      </button>
      <motion.div
        initial={false}
        animate={{ height: isOpen ? 'auto' : 0, opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.3 }}
        className="overflow-hidden"
      >
        <ol className="list-decimal list-inside space-y-2 pt-2 pl-6 text-left">
          {steps.map((step, index) => (
            <li key={index} className="text-gray-600">{step}</li>
          ))}
        </ol>
      </motion.div>
    </div>
  );
};


function Join() {

  return (
    <div className="bg-gray-50 min-h-screen py-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-base font-semibold text-neu-red tracking-wide uppercase">Be a part of it</h2>

            <h1
                className="text-6xl font-bold text-gray-900 mb-8 text-center"
                initial={{opacity: 0, y: -20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
            >
                Join the TUMOR Lab
            </h1>

            <section
                className="mb-16"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{delay: 0.2, duration: 0.5}}
            >

            </section>

            <section
                className="mb-16"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{delay: 0.4, duration: 0.5}}
            >
                <div className="space-y-8">
                    <div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">Postdoctoral Researchers</h3>
                        <p className="text-gray-600">
                            Lead innovative projects and mentor graduate students while developing your independent
                            research trajectory within our lab's focus areas.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">Graduate Students</h3>
                        <p className="text-gray-600">
                            Engage in cutting-edge research, develop advanced technical skills, and contribute to
                            high-impact publications. We welcome applications from students with diverse academic
                            backgrounds.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">Undergraduate Students</h3>
                        <p className="text-gray-600">
                            Gain valuable hands-on research experience and contribute to ongoing projects. This
                            opportunity is ideal for students considering graduate studies or a career in biomedical
                            research.
                        </p>
                    </div>
                </div>
            </section>

            <section
                className="mb-16"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{delay: 0.6, duration: 0.5}}
            >
                <h2 className="text-2xl font-semibold text-gray-800 mb-6">Application Process</h2>
                <ApplicationProcess
                    title="Postdoctoral Researchers"
                    steps={[
                        <span key="1">Email your CV to <a href="mailto:c.hajal@northeastern.edu"
                                                          className="text-neu-red hover:text-neu-red-dark">c.hajal@northeastern.edu</a></span>,
                        "Include a brief description of your past research",
                        "Outline your future research interests",
                        "Provide contact information for 2-3 references"
                    ]}
                />
                <ApplicationProcess
                    title="Graduate Students"
                    steps={[
                        <span key="1">If admitted to Northeastern: Email your CV and research interests to <a
                            href="mailto:c.hajal@northeastern.edu"
                            className="text-neu-red hover:text-neu-red-dark">c.hajal@northeastern.edu</a></span>,
                        "If not yet admitted: Apply to the Mechanical and Industrial Engineering graduate program",
                        "After applying, email us your CV and research interests",
                        "We will review applications and contact promising candidates for interviews"
                    ]}
                />
                <ApplicationProcess
                    title="Undergraduate Students"
                    steps={[
                        <span key="1">Email your CV to <a href="mailto:c.hajal@northeastern.edu"
                                                          className="text-neu-red hover:text-neu-red-dark">c.hajal@northeastern.edu</a></span>,
                        "Include a short description of your research interests",
                        "Mention any relevant coursework or previous research experience",
                        "Indicate your availability (hours per week, preferred semesters)"
                    ]}
                />
            </section>


        </div>
    </div>
  );
}

export default Join;
