import React from 'react';
import News from '../components/News';

const NewsPage = () => {
  return (
    <div className="bg-white min-h-screen py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-neu-red tracking-wide uppercase">What we've been up to</h2>
          <h1 className="text-3xl font-extrabold text-gray-900 sm:text-6xl">
            Lab News
          </h1>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Stay updated with the latest happenings at the TUMOR Lab
          </p>
        </div>
        <div className="mt-12">
          <News/>
        </div>
      </div>
    </div>
  );
};

export default NewsPage;