import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-neu-black text-gray-400 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center text-center">
          <img
            className="h-32 mb-4"
            src="/tumor-lab-white.svg"
            alt="TUMOR Lab"
          />
          <p className="text-sm mb-4 max-w-2xl">
            Therapeutic Understanding through Microfluidic Organ-on-chip Research
          </p>
          <p className="text-xs">
            &copy; {currentYear} TUMOR Lab, Northeastern University. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
