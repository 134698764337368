import React, { useEffect, useState, useRef, useCallback } from "react";

function Publications() {
  const [papers, setPapers] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const papersPerPage = 6;
  const base_url = "https://eutils.ncbi.nlm.nih.gov/entrez/eutils/";
  const search_url = base_url + "esearch.fcgi";
  const summary_url = base_url + "esummary.fcgi";
  const papersRef = useRef(null);

  const fetchPublications = useCallback(async () => {
    setLoading(true);
    const search_params = new URLSearchParams({
      db: "pubmed",
      retmode: "json",
      term: "Cynthia Hajal[Author]",
      retmax: 1000,
    });
    try {
      const search_response = await fetch(search_url + "?" + search_params);
      const search_data = await search_response.json();
      const pubmed_ids = search_data["esearchresult"]["idlist"];
      const summary_params = new URLSearchParams({
        db: "pubmed",
        retmode: "json",
        id: pubmed_ids.join(","),
      });
      const summary_response = await fetch(summary_url + "?" + summary_params);
      const summary_data = await summary_response.json();
      let fetchedPapers = Object.values(summary_data["result"]).filter(
        (item) => item.uid,
      );
      fetchedPapers = fetchedPapers.map((item) => {
        const [year, month, day] = item.pubdate.split(" ");
        const monthNumber = month
          ? new Date(Date.parse(month + " 1, " + year)).getMonth()
          : 0;
        const parsedDate = new Date(year, monthNumber, day || 1);
        return {
          uid: item.uid,
          title: item.title,
          publication_date: item.pubdate,
          source: item.source,
          authors: item.authors.map((author) => author.name),
          first_author: item.sortfirstauthor,
          last_author: item.lastauthor,
          url: `https://pubmed.ncbi.nlm.nih.gov/${item.uid}`,
          date: parsedDate,
        };
      });
      fetchedPapers.sort((a, b) => b.date - a.date);
      setPapers(fetchedPapers);
      setLoading(false);
      setRetryCount(0);
    } catch (error) {
      console.error(error);
      if (retryCount < 3) {
        setRetryCount(retryCount + 1);
      } else {
        setLoading(false);
      }
    }
  }, [retryCount, search_url, summary_url]);

  useEffect(() => {
    fetchPublications();
  }, [fetchPublications]);

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const start = page * papersPerPage;
  const end = start + papersPerPage;

  return (
    <div className="bg-gray-50 min-h-screen py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
        <h2 className="text-base font-semibold text-neu-red tracking-wide uppercase">What we do</h2>
        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl mb-6 text-center">
          Our Publications
        </h1>
        </div>
        <p className="text-xl text-gray-600 mb-12 text-center max-w-3xl mx-auto">
          Explore our latest research findings and contributions to the field of cancer biology and microfluidics.
        </p>
        <p className="text-lg text-gray-500 mb-12 text-center">
          For a complete list, visit{" "}
          <a
              href="https://scholar.google.com/citations?user=kIsGWqUAAAAJ&hl=en"
              target="_blank"
              rel="noreferrer"
              className="text-neu-red hover:text-neu-red-dark underline"
          >
            Dr. Cynthia Hajal's Google Scholar profile
          </a>
          .
        </p>

        {!loading && (
            <div className="flex justify-between items-center mb-8" ref={papersRef}>
              <button
                  onClick={handlePrevPage}
                  disabled={page === 0}
                  className="text-gray-500 hover:text-neu-red disabled:opacity-50 transition-colors duration-300 focus:outline-none"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7"/>
                </svg>
              </button>
              <span className="text-lg text-gray-700 font-medium">
              Page {page + 1} of {Math.ceil(papers.length / papersPerPage)}
            </span>
              <button
                  onClick={handleNextPage}
                  disabled={end >= papers.length}
                  className="text-gray-500 hover:text-neu-red disabled:opacity-50 transition-colors duration-300 focus:outline-none"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/>
                </svg>
              </button>
            </div>
        )}

        {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-neu-red"></div>
            </div>
        ) : (
            <div className="min-h-[550px] flex flex-col justify-start">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {papers.slice(start, end).map((paper, index) => (
                    <div key={index}
                         className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-shadow duration-300">
                      <div className="p-6">
                        <div className="flex items-center justify-between mb-4">
                      <span className="text-sm font-semibold text-neu-red bg-neu-red-light px-3 py-1 rounded-full">
                        {new Date(paper.date).getFullYear()}
                      </span>
                          <span className="text-sm text-gray-500">{paper.source}</span>
                        </div>
                        <h3 className="text-xl font-semibold text-gray-900 mb-2 line-clamp-2">
                          {paper.title}
                        </h3>
                        <p className="text-sm text-gray-600 mb-4 line-clamp-2">
                          {paper.authors.join(", ")}
                        </p>
                        <a
                            href={paper.url}
                            target="_blank"
                            rel="noreferrer"
                            className="text-neu-red hover:text-neu-red-dark font-medium inline-flex items-center"
                        >
                          Read More
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none"
                               viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M14 5l7 7m0 0l-7 7m7-7H3"/>
                          </svg>
                        </a>
                      </div>
                    </div>
                ))}
              </div>
            </div>
        )}
      </div>
    </div>
  );
}

export default Publications;
