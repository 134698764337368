import React from 'react';
import { Link } from 'react-router-dom';
import Banner from '../images/banner.jpg';
import News from '../components/News';

const ResearchArea = ({ title, description, icon }) => (
  <div className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-500 hover:scale-105">
    <div className="p-6">
      <div className="flex items-center justify-center w-12 h-12 rounded-md bg-neu-red text-white mb-4">
        {icon}
      </div>
      <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const Home = () => {
const researchAreas = [
  {
    title: "Vascular fluid flows",
    description: "We study vascular fluid flows in the context of drug delivery to tumors, using advanced microfluidic techniques to model complex vascular networks.",
    icon: (
      // Icon representing fluid flow in vascular systems
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7 7-7 7M21 8l-7 7 7 7" />
      </svg>
    )
  },
  {
    title: "Tumor tissue properties",
    description: "Our research investigates tumor tissue properties and their role in cancer progression, focusing on the physical and biological characteristics that influence tumor growth and metastasis.",
    icon: (
      // Icon representing microscopic view of cells
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 2a10 10 0 110 20 10 10 0 010-20zm0 6a4 4 0 100 8 4 4 0 000-8z" />
      </svg>
    )
  },
  {
    title: "Chemotherapy-induced alterations",
    description: "We examine chemotherapy-induced alterations to the tumor vasculature and their consequences on patient response to treatment, aiming to optimize therapeutic strategies and improve clinical outcomes.",
    icon: (
      // Icon representing medical treatment or cellular changes
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12H9m2-6v12m-7.071 2.929L5.929 19m12.142 0l-1.414 1.414M4.222 4.222l1.414 1.414m13.728 0l-1.414 1.414M12 3a9 9 0 110 18 9 9 0 010-18z" />
      </svg>
    )
  },
];

  return (
      <div className="bg-white overflow-hidden">
        {/* Hero Section */}
        <div className="relative bg-white overflow-hidden">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="pt-16 pb-8 sm:pt-24 sm:pb-12 lg:pt-32 lg:pb-24">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
                <div className="lg:max-w-2xl">
                  <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                    <span className="block xl:inline">Welcome to the</span>{' '}
                    <span className="block text-neu-red xl:inline">TUMOR Lab</span>
                  </h1>
                  <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg md:mt-5 md:text-xl lg:text-lg xl:text-xl">
                    Therapeutic Understanding through Microfluidic Organ-on-chip Research
                  </p>
                  <div className="mt-8 sm:mt-10">
                    <div className="rounded-md shadow inline-block mr-4 mb-4">
                      <Link
                          to="/research"
                          className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-neu-red hover:bg-neu-red-dark md:py-4 md:text-lg md:px-10"
                      >
                        Our Research
                      </Link>
                    </div>
                    <div className="rounded-md shadow inline-block">
                      <Link
                          to="/join"
                          className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-neu-red bg-neu-red-light hover:bg-neu-red-lighter md:py-4 md:text-lg md:px-10"
                      >
                        Join Our Team
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="mt-12 lg:mt-0 lg:-mr-16 lg:relative lg:h-full">
                  <div className="aspect-w-16 aspect-h-9 lg:aspect-none">
                    <img
                        className="xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                        src={Banner}
                        alt="TUMOR Lab"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Research Areas Section */}
        <div className="bg-gray-50 py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <h2 className="text-base text-neu-red font-semibold tracking-wide uppercase">Our Focus</h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Research Areas
              </p>
              <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                Exploring the intersection of engineering and cancer biology to improve patient outcomes.
              </p>
            </div>

            <div className="mt-10">
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                {researchAreas.map((area, index) => (
                    <ResearchArea key={index} title={area.title} description={area.description} icon={area.icon}/>
                ))}
              </div>
              <div className="mt-10 text-center">
                <Link
                    to="/research"
                    className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-neu-red hover:bg-neu-red-dark transition duration-150 ease-in-out"
                >
                  Learn more about our research
                  <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Lab Photo Section */}
        <div className="bg-white py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <h2 className="text-base text-neu-red font-semibold tracking-wide uppercase">Our Team</h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Meet the TUMOR Lab Members
              </p>
              <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                Dedicated researchers pushing the boundaries of cancer research and treatment
              </p>
            </div>

            <div className="mt-10">
              <div className="relative aspect-w-16 aspect-h-9 rounded-lg shadow-lg overflow-hidden">
                <img
                    className="object-cover"
                    src='/lab_photo.jpg'
                    alt="TUMOR Lab Members"
                />
                <div
                    className="absolute inset-0 bg-neu-red bg-opacity-25 transition duration-300 ease-in-out hover:bg-opacity-0"></div>
              </div>
              <div className="mt-4 text-center">
                <Link
                    to="/team"
                    className="text-neu-red hover:text-neu-red-dark font-medium"
                >
                  Learn more about our team <span aria-hidden="true">&rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* News Section */}
        <div className="bg-gray-50 py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <h2 className="text-base text-neu-red font-semibold tracking-wide uppercase">Latest Updates</h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Lab News
              </p>
            </div>
            <News limit={3}/>
          </div>
        </div>
      </div>
  );
};

export default Home;